













import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import BhInput from '@/components/BhInput.vue';
import NoContent from '@/components/NoContent.vue';

@Component({
  components: {Loader, BhInput, NoContent},
})
export default class Invitations extends Vue {
  loading = false;
  noInvitationsMessage = 'You have no available invitations...';

  get gotActiveInvitation(): boolean {
    return vxm.invitations.invitations.token;
  }
  get gotInvitations(): boolean {
    return vxm.invitations.invitations.available;
  }
  get completionStatus(): string {
    return `Users registered: ${vxm.invitations.invitations.completed}/${vxm.invitations.invitations.total}`;
  }
  get invitationLink(): string {
    return this.gotActiveInvitation
      ? `${window.location.origin}/sign-up?inviteToken=${vxm.invitations.invitations.token}`
      : '';
  }

  mounted() {
    if (this.gotInvitations && !this.gotActiveInvitation) {
      this.getNewInvitation();
    }
  }

  getNewInvitation(): void {
    this.loading = true;
    vxm.invitations.getNewInvitation().finally(() => {
      this.loading = false;
    });
  }
  copyUrl(): void {
    if (!this.invitationLink) {
      return;
    }
    try {
      const dummy = document.createElement('input');
      document.body.appendChild(dummy);
      dummy.value = this.invitationLink;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      this.$toasted.show('Link is copied successfully.', {
        className: 'toasted-info',
      });
    } catch {
      this.$toasted.show('Error happened while copying to clipboard', {
        className: 'toasted-error',
      });
    }
  }
}
